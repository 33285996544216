import React from 'react'
import {
  Avatar,
  AvatarSkeleton,
  HStack,
  Text,
  TextSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { upperFirst } from 'lodash'

const getGradeColor = (grade?: string) => {
  switch (grade) {
    case 'developing':
      return Token.color.neutral
    case 'performing':
      return Token.color.green
    case 'unsatisfactory':
    case 'exceeding':
    case 'exceptional':
    default:
      // TODO: https://revolut.atlassian.net/browse/PERF-5738
      // waiting feedback from design to use correct colors
      return Token.color.neutral
  }
}

const getGradeIconName = (grade?: string): ResolveIconType | undefined => {
  switch (grade) {
    case 'developing':
      return 'TurboTransfer'
    case 'performing':
      return 'SocialLike'
    case 'unsatisfactory':
    case 'exceeding':
    case 'exceptional':
    default:
      // TODO: https://revolut.atlassian.net/browse/PERF-5738
      // waiting feedback from design to use correct icons
      return 'QuestionSign'
  }
}

type OverallGradeProps = {
  grade?: string
  loading: boolean
}

export const OverallGrade = ({ grade, loading }: OverallGradeProps) => {
  if (loading) {
    return (
      <HStack gap="s-16" align="center" data-testid="performance-overall-loading">
        <AvatarSkeleton size={76} />
        <VStack>
          <TextSkeleton variant="heading3" width="120px" />
          <Text variant="body2" whiteSpace="nowrap">
            Overall grade
          </Text>
        </VStack>
      </HStack>
    )
  }
  const color = getGradeColor(grade)
  return (
    <HStack gap="s-16" align="center" data-testid="performance-overall">
      <Avatar color={color} progress={1} progressColor={color} size={76} useIcon="Trophy">
        {grade && (
          <Avatar.Badge
            backgroundColor={getGradeColor(grade)}
            color={Token.color.white}
            position="top-right"
            useIcon={getGradeIconName(grade)}
            size={24}
          />
        )}
      </Avatar>
      <VStack>
        <Text color={getGradeColor(grade)} variant="heading3" whiteSpace="nowrap">
          {upperFirst(grade) || 'Not assessed'}
        </Text>
        <Text variant="body2" whiteSpace="nowrap">
          Overall grade
        </Text>
      </VStack>
    </HStack>
  )
}
