import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PerformanceOverview } from '@src/pages/Forms/Candidate/Performance/PerformanceOverview'
import { Token } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'

type PerformanceProps = {
  round?: InterviewRoundInterface
}

export const Performance = ({ round }: PerformanceProps) => {
  return (
    <PageBody maxWidth={Token.breakpoint.lg}>
      <PerformanceOverview round={round} />
    </PageBody>
  )
}
