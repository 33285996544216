import React from 'react'
import { Grid, Text, VStack, Widget } from '@revolut/ui-kit'
import { useGetRoundSummary } from '@src/api/recruitment/interviews'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { OverallGrade } from '@src/pages/Forms/Candidate/Performance/OverallGrade'
import { ResultHeader } from '@src/pages/Forms/Candidate/Performance/ResultHeader'

type PerformanceOverviewProps = {
  round?: InterviewRoundInterface
}

export const PerformanceOverview = ({ round }: PerformanceOverviewProps) => {
  const { data, isLoading } = useGetRoundSummary(round?.id)
  return (
    <Widget p="s-16">
      <Grid
        columns={{ all: 1, lg: 'min-content 1fr' }}
        columnGap="s-48"
        rowGap="s-16"
        placeItems={{ lg: 'center start' }}
      >
        <OverallGrade grade={data?.suggested_grade} loading={isLoading} />
        <VStack width="100%">
          <Text variant="emphasis3" mb="s-16">
            Assessment
          </Text>
          <Grid columns={{ all: 1, lg: 3 }} width="100" gap="s-16">
            <ResultHeader
              loading={isLoading}
              value={data?.skill_ratings?.skill?.suggested_grade}
              type="skills"
            />
            <ResultHeader
              loading={isLoading}
              value={data?.skill_ratings?.value?.suggested_grade}
              type="values"
            />
            <ResultHeader
              label="Expected seniority"
              loading={isLoading}
              type="seniority"
              value={data?.suggested_seniority?.name}
            />
          </Grid>
        </VStack>
      </Grid>
    </Widget>
  )
}
