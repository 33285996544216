import React from 'react'
import {
  Avatar,
  AvatarSkeleton,
  HStack,
  Text,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { upperFirst } from 'lodash'

type ResultType = 'skills' | 'values' | 'seniority'

const getResultIcon = (type: ResultType, grade?: string): ResolveIconType | undefined => {
  if (type === 'seniority') {
    return 'Profile'
  }
  switch (grade) {
    case 'basic':
      return 'TurboTransfer'
    case 'intermediate':
    case 'performing':
    case 'not-assessed':
      return 'Check'
    case 'non-existent':
    case 'advanced':
    case 'expert':
    case 'unsatisfactory':
    case 'developing':
    case 'exceeding':
    case 'exceptional':
    default:
      // TODO: https://revolut.atlassian.net/browse/PERF-5738
      // waiting feedback from design to use correct icons
      return 'QuestionSign'
  }
}

const convertOperators = (str: string) =>
  str.replace('_minus', ' -').replace('_plus', ' +')

type ResultHeaderProps = {
  label?: string
  loading: boolean
  type: ResultType
  value?: string
}

export const ResultHeader = ({ label, loading, type, value }: ResultHeaderProps) => {
  if (loading) {
    return (
      <HStack gap="s-16" data-testId={`result-header-${type}-loading`}>
        <AvatarSkeleton />
        <VStack>
          <TextSkeleton variant="emphasis1" width="120px" />
          <TextSkeleton variant="body2" width="120px" />
        </VStack>
      </HStack>
    )
  }
  return (
    <HStack gap="s-16" data-testId={`result-header-${type}`}>
      <Avatar useIcon={getResultIcon(type, value)} />
      <VStack>
        <Text variant="emphasis1">
          {value ? convertOperators(upperFirst(value)) : 'Not assessed'}
        </Text>
        <Text variant="body2">{label || upperFirst(type)}</Text>
      </VStack>
    </HStack>
  )
}
